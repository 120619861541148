.fadeIn {
  animation: fadeIn ease-in-out 0.8s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.highlight {
  background-image: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 50%,
    var(--color-bg-accent-lighten, #fe9) 0
  );
  background-size: 200%;
  background-position: 0;
  transition: background-position 1s;
}

.highlighted {
  background-position: -100%;
}
