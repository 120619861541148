.card {
  @apply transition duration-300 ease-in-out rounded-md;

  &,
  &:focus-within,
  &:active {
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.1), 0px 4px 6px -1px rgba(0, 0, 0, 0.14);
  }

  @media (hover: hover) {
    &:hover {
      box-shadow: 0px 10px 10px -5px rgba(0, 0, 0, 0.04), 0px 20px 25px -5px rgba(0, 0, 0, 0.1);
    }
  }
}

.shortcutsCardContainer {
  @media (min-width: 768px) and (max-width: 1024px) {
    border-radius: 8px;
    box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.30), 0px 2px 4px -1px rgba(0, 0, 0, 0.10), 0px 4px 6px -1px rgba(0, 0, 0, 0.14);
  }
}