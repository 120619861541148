.sidebar {
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  overflow: auto;
  z-index: 999;
  outline: 0;
  will-change: transform;
  transition: transform 0.3s ease;
  transform: translate3d(-100%, 0, 0);
  &[data-enter] {
    transform: translate3d(0, 0, 0);
  }
}

.sidebarBackdrop {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  &[data-enter] {
    opacity: 1;
  }
}
