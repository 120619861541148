.navSubmenu {
  @apply h-auto overflow-hidden;
  will-change: content;
  transition: max-height 350ms ease-in-out;
  max-height: 0;

  &[data-enter] {
    @apply max-h-screen;
  }
}

.navSubmenuIcon {
  will-change: transform;
  transition: transform 300ms ease;
  transform: rotate(0deg);
  &.isExpanded {
    transform: rotate(90deg);
  }
}

.navSubItem {
  @apply bg-white text-gray-800 rounded;

  &:active,
  &:focus {
    @apply bg-secondary-darkest;
  }
  @media (hover: hover) {
    &:hover {
      @apply bg-gray-300;
    }
  }
}

.navItem {
  @apply bg-white text-gray-800 rounded;

  &:active,
  &:focus {
    @apply bg-white;
  }
  @media (hover: hover) {
    &:hover {
      @apply bg-gray-300;
    }
  }
}

.navActiveItem {
  @apply bg-secondary-darken text-primary-darkest rounded;

  &:active,
  &:focus {
    @apply bg-secondary-darkest;
  }
  @media (hover: hover) {
    &:hover {
      @apply bg-gray-300;
    }
  }
}

.navInactiveItem {
  @apply bg-white text-gray-800 rounded;

  &:active,
  &:focus {
    @apply bg-secondary-darkest;
  }
  @media (hover: hover) {
    &:hover {
      @apply bg-gray-300;
    }
  }
}
